@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply h-full;
}

body {
  @apply antialiased text-gray-600 h-full flex flex-col;
}

#__next {
  @apply h-full;
}

a {
  @apply text-blue-600 hover:text-blue-800;
}

kbd ~ kbd {
  margin-left: 0.5em;
}

#typingInput {
  opacity: 0;
}

.retry {
  background-color: #d32f2f;
  color: white;
  border: none;
}

.continue {
  background-color: #689f38;
  color: white;
  border: none;
}

.button-form {
  padding: 1rem 0;
  margin: 1rem 0 0 0;
  width: 100%;
}

.button-form h2 {
  margin: 1rem 0;
}

.button-form--continue {
  background-color: #dcedc8;
  color: #689f38;
}

.button-form--retry {
  background-color: #ffcdd2;
  color: #d32f2f;
}

.key--small {
  display: inline-flex;
  justify-content: center;
  min-width: 40px;
  padding: 0.4em 0.8em;
  font-family: inherit;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.65);
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 20%), inset 0 -3px 0 rgb(0 0 0 / 6%);
  border-radius: 0.25rem;
  overflow-wrap: break-word;
  overflow-x: auto;
  font-weight: 500;
  font-size: 1rem;
}

.practice {
  grid-gap: 24px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: -webkit-min-content 1fr -webkit-min-content;
  grid-template-rows: min-content 1fr min-content;
  height: 100%;
  left: 0;
  min-height: 460px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (min-width: 700px) {
  .practice {
    grid-gap: 0;
    grid-template-rows: 80px 1fr 160px;
    min-height: 690px;
  }
}

@media (min-width: 700px) and (max-height: 650px) {
  .practice {
    grid-template-rows: 60px 450px 160px;
    min-height: 680px;
  }
}

.global--hide-spin-buttons::-webkit-inner-spin-button,
.global--hide-spin-buttons::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

span.highlight {
  font-weight: bolder;
  background: linear-gradient(
      104deg,
      rgba(250, 204, 21, 0) 0.9%,
      rgba(250, 204, 21, 1.25) 2.4%,
      rgba(250, 204, 21, 0.5) 5.8%,
      rgba(250, 204, 21, 0.1) 93%,
      rgba(250, 204, 21, 0.7) 96%,
      rgba(250, 204, 21, 0) 98%
    ),
    linear-gradient(
      183deg,
      rgba(250, 204, 21, 0) 0%,
      rgba(250, 204, 21, 0.3) 7.9%,
      rgba(250, 204, 21, 0) 15%
    );
  -webkit-box-decoration-break: clone;
  margin: 0;
  border-radius: 7.5px;
  text-shadow: -12px 12px 9.8px rgba(250, 204, 21, 0.7),
    21px -18.1px 7.3px rgba(255, 255, 255, 1),
    -18.1px -27.3px 30px rgba(255, 255, 255, 1);
  transform: rotate(15deg);
}
